import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'gatsby'
import alibabatrek from '../../assets/logo/alibabatrek.svg';
import alibabatrekblack from '../../assets/logo/alibabatrekblack.svg'
import whatsappLogo from '../../assets/logo/whatsapp.svg'
// import outerTheme from '../theme'
// import { ThemeProvider } from '@material-ui/styles';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: 0,
        backgroundColor: '#52bdde',



    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    alibabatrek: {

        width: 70,
        height: 70,


        flexGrow: 1,
    },
    alibabatrekblack: {

        width: 110,
        height: 110,


        flexGrow: 1,
    },
    whatsappLogo: {

        width: 30,
        height: 30,


        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            marginRight: theme.spacing(2),

        },
    },
}));

export default function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <img src={alibabatrekblack} alt="Alibabatrek" className={classes.alibabatrekblack} />

            <Typography variant="h6" noWrap>
                <Link to={`/`} style={{ textDecoration: 'none' }}>
                    AlibabaTrek

                        </Link>
            </Typography>
            <Divider />
            <List>
                <ListItem button key={2}>
                    <ListItemText primary="Iran Tours" />
                </ListItem>
                <ListItem button key={2}>
                    <ListItemText primary="Iran Tour Packages" />
                </ListItem>

                <ListItem button key={2}>
                    <ListItemText primary="Services" />
                </ListItem>
                <ListItem button key={2}>
                    <ListItemText primary="Help" />
                </ListItem>
            </List>
            <Divider />
            <List>

                <ListItem button key={2}>
                    <ListItemText primary="Tour 1" />
                </ListItem>
                <ListItem button key={2}>
                    <ListItemText primary="Tour 2" />
                </ListItem>
                <ListItem button key={2}>
                    <ListItemText primary="Tour 3" />
                </ListItem>

            </List>
        </div>
    );

    return (
        <React.Fragment>
            {/* <ThemeProvider theme={outerTheme}> */}

            <div className={classes.root}>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.title}>

                                <Link to={`/`} style={{ textDecoration: 'none' }}>

                                    <img src={alibabatrek} alt="Alibabatrek" className={classes.alibabatrek} />


                                </Link>
                            </div>

                            <div className={classes.sectionDesktop}>
                                <Button color="inherit" size="small">Iran Tours</Button>
                                <Button color="inherit" size="small">Cities</Button>
                                <Button color="inherit" size="small">Services</Button>
                                <Button color="inherit" size="small">Help</Button>




                            </div>
                            <a target="_new" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=989025555015">
                                <img src={whatsappLogo} alt="Alibabatrek" className={classes.whatsappLogo} />
                            </a>
                        </Toolbar>
                    </AppBar>
                    {/* <AppBar position="sticky" className="not-scrolled">
                    <Toolbar>


                        
                   </Toolbar> 

                </AppBar>*/}
                </div>
                <nav className={classes.drawer} aria-label="Mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>

                </nav>
            </div >
            {/* </ThemeProvider> */}
        </React.Fragment>
    );
}

ResponsiveDrawer.propTypes = {
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
};
