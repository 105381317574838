import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { VerticleButton as ScrollUpButton } from "react-scroll-up-button";



function FooterNotes() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Alibabatrek for goods '}

        </Typography>
    );
}
const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));


export default function MainFooter() {
    const classes = useStyles();

    return (
        <React.Fragment>

            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    Footer
        </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Something here to give the footer a purpose!
        </Typography>
                <FooterNotes />
            </footer>
         <ScrollUpButton />
        </React.Fragment>
    );
}